import type { Id } from "@/src/common/utils/types";
import { createProvider } from "@/src/common/utils/zustand";
import type { InvoiceModel } from "@/src/core/invoice/domain/models/invoice_model";
import InvoiceController from "@/src/ui/pages/invoice/controllers/invoice_controller";

interface InvoiceState {
  rows: InvoiceModel[];
  isLoading: boolean;
  getAll: (channel?: Id) => Promise<void>;
}

export const useListInvoiceProvider = createProvider<InvoiceState>(() => (set) => ({
  rows: [],
  isLoading: false,

  async getAll(channel?: Id) {
    set({ isLoading: true });
    try {
      const data = await new InvoiceController().getAll(channel);
      set({ rows: data });
    } finally {
      set({ isLoading: false });
    }
  }
}));
