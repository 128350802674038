import styled from "styled-components";
import { spacing } from "@/src/ui/styles/spacing";
import { px2rem } from "@/src/ui/styles/utils";
import { colors } from "@/src/ui/styles/colors";
import { InputLabel } from "@mui/material";
import { typography } from "@/src/ui/styles/typography";

interface LabelProps {
  backgroundColor?: string;
}

const Label = styled(InputLabel)<LabelProps>`
  font-size: ${px2rem(12)} !important;
  color: ${colors["grayscale-black-accent"]};
  margin-bottom: ${px2rem(spacing.size1)};
  transform: translateY(-${px2rem(spacing.size1)});
  position: relative;
  top: -25px !important;
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};
  padding: 2px 5px;
  border-radius: 4px;
`;

const Error = styled.div`
  margin: ${px2rem(spacing.size1)} 0;

  p {
    ${typography.errorText}
  }
`;

export default { Label, Error };
