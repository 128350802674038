import { createSlice } from "@reduxjs/toolkit";
import type { MRT_ColumnFiltersState } from "material-react-table";

interface FilterTableState {
  [tableId: string]: MRT_ColumnFiltersState | undefined;
}

const initialState = {
  user: null,
  userPermissions: null,
  channel: undefined,
  filterTable: {} as FilterTableState
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
      return state;
    },
    removeUserInfo: (state) => {
      state.user = null;
      return state;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
      return state;
    },
    removeUserPermissions: (state) => {
      state.userPermissions = null;
      return state;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
      return state;
    },
    removeChannel: (state) => {
      state.channel = undefined;
      return state;
    },
    setFilterTable: (state, action) => {
      const { tableId, filters } = action.payload;
      state.filterTable[tableId] = filters;
      return state;
    },
    removeFilterTable: (state, action) => {
      const { tableId } = action.payload;
      state.filterTable[tableId] = undefined;
      return state;
    }
  }
});

export const {
  setUserInfo,
  removeUserInfo,
  setUserPermissions,
  removeUserPermissions,
  setChannel,
  removeChannel,
  setFilterTable,
  removeFilterTable
} = appSlice.actions;

export default appSlice.reducer;
