import { createStore, useStore } from "zustand";
import type { UserState } from "@/src/ui/view_models/user_state";
import type { LoginModel } from "@/src/core/user/domain/models/login_model";
import UserController from "../controller/user_controller";
import store from "@/src/redux/store";
import { setUserInfo, setChannel, setUserPermissions, removeUserInfo, removeUserPermissions, removeChannel } from "@/src/redux/slices/appSlice";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const serializeUser = (user: any) => {
  if (!user) return null;

  return {
    id: user.id,
    name: user.name,
    surname: user.surname,
    email: user.email,
    isActive: user.isActive ?? false,
    isDeleted: user.isDeleted ?? false,
    isSuperadmin: user.isSuperadmin ?? false,
    createdAt: user.createdAt,
    role: user.role
  };
};

export const userProvider = createStore<UserState>((set) => ({
  user: undefined,
  userPermission: undefined,
  channel: undefined,
  async me() {
    const user = await UserController.me();
    const serializableUser = serializeUser(user);
    set({ user: user });
    store.dispatch(setUserInfo(serializableUser));
  },
  async logout() {
    await UserController.logout();
    store.dispatch(removeUserInfo());
    store.dispatch(removeUserPermissions());
    store.dispatch(removeChannel());
    set({ user: undefined });
  },
  async login(values: LoginModel) {
    const session = await UserController.login(values);
    const serializableUser = serializeUser(session.user);
    set({ user: session.user });
    store.dispatch(setUserInfo(serializableUser));

    const permissions = await UserController.permissions();
    set({ userPermission: permissions });
    store.dispatch(setUserPermissions(permissions));
  },
  async permissions() {
    const result = await UserController.permissions();
    set({ userPermission: result });
    store.dispatch(setUserPermissions(result));
  },
  async selectChannel(channel?: number) {
    set({ channel: channel });
    store.dispatch(setChannel(channel));
  }
}));

export function useUserProvider(): UserState;
export function useUserProvider<T>(selector: (state: UserState) => T, equals?: (a: T, b: T) => boolean): T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useUserProvider(selector?: any, equals?: any) {
  return useStore(userProvider, selector, equals);
}
