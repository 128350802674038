import { errorProvider } from "@/src/ui/provider/error.provider";
import type { ApiError } from "../interfaces/api_error_model";
import type { IRestDataError } from "../interfaces/rest_data_source";
import errorTranslations from "@/src/ui/i18n/locales/es/error.json";
import { t } from "i18next";

export async function withErrorHandler<T>(promise: Promise<T>) {
  try {
    return await promise;
  } catch (e) {
    console.error(e);
    if (Array.isArray(e)) {
      errorProvider.getState().setErrors(e);
    } else {
      const error: ApiError = {
        code: "",
        field: "",
        message: "",
        source: ""
      };
      errorProvider.getState().setErrors([error]);
    }
    throw e;
  }
}

export function handleResponseError(responseError: IRestDataError): ApiError[] {
  const errorData = responseError?.response?.data;
  if (!errorData || !errorData.detail) {
    const isTranslatedCode = errorData?.code ? Object.keys(errorTranslations).includes(errorData?.code) : "";

    const message: string = isTranslatedCode ? t("error:" + errorData?.code) : t("error:DEFAULT");

    return [
      {
        code: errorData?.code ?? "default",
        source: null,
        field: null,
        message: message ?? "server error"
      }
    ];
  }

  if ("code" in errorData && errorData?.code === "NINJA_VALIDATION") {
    let messages: string[] = [];

    if (Array.isArray(errorData.detail)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subcodes = (errorData as any)?.subcodes;
      messages = errorData.detail.flatMap((errorObject) => {
        return subcodes
          .map((subcode: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const subcodeValue = (errorObject as any)[subcode];
            const isTranslatedSubcode = Object.keys(errorTranslations).includes(subcodeValue);
            return isTranslatedSubcode ? t("error:" + subcodeValue) : null;
          })
          .filter(Boolean);
      });
    }

    const finalMessage = messages.length > 0 ? messages.join(" | ") : t("error:DEFAULT");

    return [
      {
        code: "NINJA_VALIDATION",
        source: null,
        field: null,
        message: finalMessage
      }
    ];
  }

  return errorData.detail as ApiError[];
}
