import { Navigate, useRoutes } from "react-router-dom";
import { lazy } from "react";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useMutationMarketerMarginProvider } from "@/src/ui/pages/marketer_margin/provider/mutation_marketer_margin.provider";
import { useListRateTypeProvider } from "@/src/ui/pages/rate_type/views/rate_type_page/provider/list_rate_type.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useListRateProvider } from "@/src/ui/pages/rate/provider/list_rate.provider";

const CreateMarketerMarginPage = lazy(() => import("@/src/ui/pages/marketer_margin/views/create_marketer_margin_page/create_marketer_margin_page"));
const DetailMarketerMarginPage = lazy(() => import("@/src/ui/pages/marketer_margin/views/detail_marketer_margin_page/detail_marketer_margin_page"));
const EditMarketerMarginPage = lazy(() => import("@/src/ui/pages/marketer_margin/views/edit_marketer_margin_page/edit_marketer_margin_page"));

const ID_PATH_PARAM = ":marketerMarginId";

export default function MarketerMarginPages() {
  const page = useRoutes([
    {
      path: paths.marketerMargin.create,
      element: <CreateMarketerMarginPage />
    },
    {
      path: paths.marketerMargin.detail + ID_PATH_PARAM,
      element: <DetailMarketerMarginPage />
    },
    {
      path: paths.marketerMargin.edit + ID_PATH_PARAM,
      element: <EditMarketerMarginPage />
    },
    { path: WILDCARD_PATH, element: <Navigate to={paths.marketerMargin.create} replace /> }
  ]);
  return (
    <AppErrorBoundary key="marketerMargin-error">
      <useListRateTypeProvider.State>
        <useListRateProvider.State>
          <useMutationMarketerMarginProvider.State>{page}</useMutationMarketerMarginProvider.State>
        </useListRateProvider.State>
      </useListRateTypeProvider.State>
    </AppErrorBoundary>
  );
}
