import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const DATE_FORMAT_TO_SHOW = "DD/MM/YYYY";
export const DATE_TIME_FORMAT_TO_SHOW = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT_DATABASE = "YYYY-MM-DD";
export const MONTH_YEAR_FORMAT = "YYYY-MM";
export const YEAR_FORMAT = "YYYY";
import utc from "dayjs/plugin/utc";

export const fromDataDateStringToDomainDate = (date: string) => {
  if (dayjs(date, DATE_FORMAT_DATABASE).isValid()) {
    return dayjs(date, DATE_FORMAT_DATABASE).format(DATE_FORMAT_TO_SHOW);
  }
  return date;
};
export const fromDataDateTimeStringToDomainDateTime = (date: string) => {
  dayjs.extend(utc);
  if (dayjs(date).isValid()) {
    return dayjs.utc(date).format(DATE_TIME_FORMAT_TO_SHOW);
  }
  return date;
};
export const fromDataDateToDomainDate = (date: Date) => dayjs(date).format(DATE_FORMAT_TO_SHOW);
export const fromDomainDateStringToDataDate = (date: string) => {
  if (dayjs(date, DATE_FORMAT_TO_SHOW).isValid()) {
    return dayjs(date, DATE_FORMAT_TO_SHOW).format(DATE_FORMAT_DATABASE);
  }
  return date;
};
export const fromDomainDateToFilterDate = (date: string) => dayjs(date, DATE_FORMAT_TO_SHOW).add(1, "day").toISOString();
export const fromYearToDate = (date: string) => dayjs(date, YEAR_FORMAT).toISOString();
export const fromDataDateToYear = (date: string) => dayjs(date, DATE_FORMAT_DATABASE).format(YEAR_FORMAT);

export function isValidDatabaseDateFormat(dateString: string) {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}
