import { createProvider } from "@/src/common/utils/zustand";
import type { Id } from "@/src/common/utils/types";
import type { InvoiceModel } from "@/src/core/invoice/domain/models/invoice_model";
import InvoiceController from "../controllers/invoice_controller";

interface Props {
  getInvoiceDetails: (id?: Id) => void;
  details: InvoiceModel | null;
}

export const useInvoiceDetailsProvider = createProvider<Props>(() => {
  return (set) => ({
    details: null,
    async getInvoiceDetails(id?: Id) {
      try {
        if (!id) return;
        const response = await new InvoiceController().getOneById(id);
        set({ details: response });
      } catch {
        set({ details: null });
      }
    }
  });
});
