import { locator } from "@/src/core/app/ioc";
import type { IocProvider } from "@/src/core/app/ioc/interfaces";
import { TYPES } from "@/src/core/app/ioc/types";
import type { Id } from "@/src/common/utils/types";
import { withErrorHandler } from "@/src/common/utils/errors";
import type { GetInvoiceDetailsUseCase } from "@/src/core/invoice/domain/use_cases/get_invoice_details_use_case";
import type { GetInvoicesUseCase } from "@/src/core/invoice/domain/use_cases/get_invoices_use_case";

export default class InvoiceController {
  async getOneById(id: Id) {
    const getInvoiceDetailsUseCase = await locator.get<IocProvider<GetInvoiceDetailsUseCase>>(TYPES.GetInvoiceDetailsUseCase)();
    return await withErrorHandler(getInvoiceDetailsUseCase.execute(id));
  }

  async getAll(channel?: Id) {
    const invoicesUseCase = await locator.get<IocProvider<GetInvoicesUseCase>>(TYPES.GetInvoicesUseCase)();
    return await withErrorHandler(invoicesUseCase.execute(channel));
  }
}
