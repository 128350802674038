import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useInvoiceDetailsProvider } from "@/src/ui/pages/invoice/provider/mutation_invoice.provider";
import { useListInvoiceProvider } from "@/src/ui/pages/invoice/provider/list_invoice_provider";
const ListInvoicePage = lazy(() => import("@/src/ui/pages/invoice/views/invoice_page/invoice_page"));
const DetailInvoicePage = lazy(() => import("@/src/ui/pages/invoice/views/detail_invoice_page/detail_invoice_page"));
const ID_PATH_PARAM = ":invoiceId";

export default function InvoicePages() {
  const navigate = useNavigate();

  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    const list = [];
    list.push(
      {
        index: true,
        element: (
          <useListInvoiceProvider.State>
            <ListInvoicePage />
          </useListInvoiceProvider.State>
        )
      },
      {
        path: paths.rate.detail + ID_PATH_PARAM,
        element: (
          <useInvoiceDetailsProvider.State>
            <DetailInvoicePage />
          </useInvoiceDetailsProvider.State>
        )
      },
      { path: WILDCARD_PATH, element: <Navigate to={paths.supplyPoint.index} replace /> }
    );
    setRouteList(list);
  }, [navigate]);

  const page = useRoutes(routeList);
  return <AppErrorBoundary key="supply_point-error">{page}</AppErrorBoundary>;
}
