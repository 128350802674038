import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { BlackLettersLogo, Home } from "../../assets/icons";
import paths from "@/src/ui/router/paths";
import IconWithTooltip from "../icon_with_tooltip/icon_with_tooltip";
import UserSettings from "../user_settings/user_settings";
import Styled from "./header.styled";
import { useEffect } from "react";
import { useUserProvider } from "../../provider/user.slice";
import { useListChannelProvider } from "../../pages/channel/views/channel_page/provider/list_channel.provider";
import store from "@/src/redux/store";
import { setChannel } from "@/src/redux/slices/appSlice";
import CreatableAutoselect from "../creatable_autoselect/creatable_autoselect";
import { colors } from "../../styles/colors";

export default function Header() {
  const { t } = useTranslation("common");
  const user = useUserProvider((state) => state.user);
  const permissions = useUserProvider((state) => state.permissions);
  const userPermissions = useUserProvider((state) => state.userPermission);
  const selectChannel = useUserProvider((state) => state.selectChannel);
  const channel = useUserProvider((state) => state.channel);
  const { rows: channels, getAll: getChannels } = useListChannelProvider((state) => state);

  useEffect(() => {
    async function prepare() {
      if (!userPermissions) {
        await permissions();
      } else {
        if (userPermissions["channels"] && userPermissions["channels"]["list"]) {
          getChannels({ noPagination: true });
        } else {
          store.dispatch(setChannel(user?.channel?.id));
        }
      }
    }

    prepare();
  }, [permissions, userPermissions, getChannels, user?.channel]);

  return (
    <Styled.Header>
      <Styled.HeaderContent>
        <Styled.HomeContent>
          <Link to={paths.home.index}>
            <BlackLettersLogo />
          </Link>
          <Link to={paths.home.index}>
            <IconWithTooltip tooltip={t("header.home")}>
              <Home />
            </IconWithTooltip>
          </Link>
        </Styled.HomeContent>

        <Styled.UserContent>
          {user && !user.channel && (
            <CreatableAutoselect
              label={t("header.channel")}
              options={channels?.map((channel) => ({
                label: channel?.name ?? "",
                value: channel?.id ?? ""
              }))}
              value={
                channels?.find((ch) => ch.id === channel)
                  ? {
                      label: channels.find((ch) => ch.id === channel)?.name ?? "",
                      value: channels.find((ch) => ch.id === channel)?.id ?? ""
                    }
                  : undefined
              }
              onChange={(selected) => {
                if (selected) {
                  selectChannel((selected as { value: number }).value);
                } else {
                  selectChannel();
                }
              }}
              placeholder={t("header.selectChannel")}
              required
              style={{ width: "250px" }}
              labelBackgroundColor={colors["brand-ivory-subtle"]}
            />
          )}
          <UserSettings />
        </Styled.UserContent>
      </Styled.HeaderContent>
    </Styled.Header>
  );
}
