import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import paths, { WILDCARD_PATH } from "@/src/ui/router/paths";
import { useMutationSavingStudyProvider } from "@/src/ui/pages/saving_study/provider/mutation_saving_study.provider";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { lazy, useEffect, useState } from "react";
import { useListSavingStudyProvider } from "@/src/ui/pages/saving_study/views/saving_study_page/provider/list_saving_study.provider";
import { useListSuggestedRatesProvider } from "@/src/ui/pages/saving_study/views/select_rate_page/provider/list_suggested_rates.provider";
import { useListRateTypeProvider } from "@/src/ui/pages/rate_type/views/rate_type_page/provider/list_rate_type.provider";
import { useListMarketerProvider } from "@/src/ui/pages/marketer/views/marketer_page/provider/list_marketer.provider";
import { useAutocompleteRateTypesProvider } from "@/src/ui/provider/autocomplete_rate_types.provider";
import { useAutocompleteMarketersProvider } from "../../rate/provider/autocomplete_marketer.provider";
import { useAutocompleteClientsProvider } from "../../supply_point/provider/autocomplete_client.provider";
import { useAutocompleteSupplyPointsProvider } from "../../contract/provider/autocomplete_supply_points.provider";
import { useAutocompleteRateProvider } from "@/src/ui/provider/autocomplete_rate.provider";
import { useListClientProvider } from "@/src/ui/pages/client/views/client_page/provider/list_client.provider";
import type { RootState, UserPermissions } from "@/src/redux/store";
import { useSelector } from "react-redux";

const ListSalvingStudyPage = lazy(() => import("@/src/ui/pages/saving_study/views/saving_study_page/saving_study_page"));
const CreateSavingsStudyPage = lazy(() => import("@/src/ui/pages/saving_study/views/create_saving_study_page/create_saving_study_page"));
const DetailSalvingStudyPage = lazy(() => import("@/src/ui/pages/saving_study/views/detail_saving_study_page/detail_saving_study_page"));
const EditSavingStudyPage = lazy(() => import("@/src/ui/pages/saving_study/views/edit_saving_study_page/edit_saving_study_page"));
const SelectRatePage = lazy(() => import("@/src/ui/pages/saving_study/views/select_rate_page/select_rate_page"));
const ContractFromSavingStudyPage = lazy(
  () => import("@/src/ui/pages/saving_study/views/contract_from_saving_study_page/contract_from_saving_study_page")
);

const ID_PATH_PARAM = ":savingStudyId";

function SavingStudyPages() {
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);

  const routes = [
    {
      index: true,
      element: <ListSalvingStudyPage />
    },
    {
      path: paths.savingStudy.detail + ID_PATH_PARAM,
      element: <DetailSalvingStudyPage />
    },
    {
      path: paths.savingStudy.edit + ID_PATH_PARAM,
      element: (
        <useAutocompleteRateTypesProvider.State builderProps={{ filterByActiveRateTypes: true }}>
          <useListMarketerProvider.State>
            <EditSavingStudyPage />
          </useListMarketerProvider.State>
        </useAutocompleteRateTypesProvider.State>
      )
    },
    {
      path: "/" + ID_PATH_PARAM + paths.savingStudy.selectRate,
      element: (
        <useAutocompleteMarketersProvider.State>
          <useListSuggestedRatesProvider.State>
            <SelectRatePage />
          </useListSuggestedRatesProvider.State>
        </useAutocompleteMarketersProvider.State>
      )
    },
    {
      path: paths.savingStudy.create,
      element: (
        <useAutocompleteRateTypesProvider.State builderProps={{ filterByActiveRateTypes: true }}>
          <useAutocompleteClientsProvider.State builderProps={{ filterByActiveClients: true }}>
            <useAutocompleteSupplyPointsProvider.State builderProps={{ filterByActiveSupplyPoints: true }}>
              <CreateSavingsStudyPage />
            </useAutocompleteSupplyPointsProvider.State>
          </useAutocompleteClientsProvider.State>
        </useAutocompleteRateTypesProvider.State>
      )
    }
  ];

  if (userPermissions["contracts"]?.create) {
    routes.push({
      path: "/" + ID_PATH_PARAM + paths.savingStudy.contract,
      element: (
        <useAutocompleteSupplyPointsProvider.State builderProps={{ filterByActiveSupplyPoints: true }}>
          <useListClientProvider.State>
            <ContractFromSavingStudyPage />
          </useListClientProvider.State>
        </useAutocompleteSupplyPointsProvider.State>
      )
    });
  }

  routes.push({
    path: WILDCARD_PATH,
    element: <Navigate to={paths.savingStudy.index} replace />
  });

  return useRoutes(routes);
}

export default function SalvingStudyPages() {
  const navigate = useNavigate();
  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    if (userPermissions) {
      if (userPermissions["saving_studies"]?.list) {
        setCanRender(true);
      } else {
        navigate("/");
      }
    }
  }, [userPermissions, navigate]);

  return (
    <AppErrorBoundary key="saving_study-error">
      <useMutationSavingStudyProvider.State>
        {canRender && (
          <useListRateTypeProvider.State>
            <useAutocompleteMarketersProvider.State builderProps={{ filterByActiveMarketers: true }}>
              <useAutocompleteRateProvider.State builderProps={{ filterByActiveRate: true }}>
                <useListSavingStudyProvider.State>
                  <SavingStudyPages />
                </useListSavingStudyProvider.State>
              </useAutocompleteRateProvider.State>
            </useAutocompleteMarketersProvider.State>
          </useListRateTypeProvider.State>
        )}
      </useMutationSavingStudyProvider.State>
    </AppErrorBoundary>
  );
}
