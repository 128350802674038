import React, { useCallback, useId, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components, type MultiValue, type SingleValue } from "react-select";
import { FormControl } from "@mui/material";
import Styled from "./creatable_autoselect.styled";
import { emptyFunction } from "@/src/common/utils";
import type { inputOption } from "@/src/common/utils/types";
import ErrorMessage from "../error_message/error_message";

type Props<T extends inputOption> = {
  options?: T[];
  onChange?: (value?: T | T[] | undefined) => void;
  onCreateOption?: (inputValue: string) => void;
  errorMessage?: string | string[];
  label?: string;
  value?: T | T[];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
  style?: React.CSSProperties;
  onFocus?: (event?: React.FocusEvent<HTMLDivElement>) => void;
  labelBackgroundColor?: string;
  startIcon?: React.ReactNode;
};

export default function CreatableAutoselect<T extends inputOption>({
  label,
  options = [],
  onChange = emptyFunction,
  onCreateOption = emptyFunction,
  value = undefined,
  placeholder = "",
  disabled = false,
  multiple = false,
  errorMessage = "",
  required = false,
  style = {},
  onFocus = emptyFunction,
  labelBackgroundColor = "#ffffff",
  startIcon
}: Props<T>) {
  const [newOptions, setNewOptions] = useState<T[]>([]);
  const id = useId();

  const handleCreateOption = useCallback(
    (inputValue: string) => {
      const newOption = { value: inputValue, label: inputValue } as T;
      setNewOptions((prev) => [...prev, newOption]);
      if (onCreateOption) {
        onCreateOption(inputValue);
      }
    },
    [onCreateOption]
  );

  const handleChange = useCallback(
    (selected: MultiValue<T> | SingleValue<T>) => {
      if (multiple) {
        onChange(Array.isArray(selected) ? ([...selected] as T[]) : []);
      } else {
        onChange((selected as T) || undefined);
      }
    },
    [onChange, multiple]
  );

  const allowCreation = onCreateOption !== emptyFunction;

  return (
    <FormControl required={required} style={style} fullWidth>
      {label && (
        <Styled.Label id={id} backgroundColor={labelBackgroundColor}>
          {label}
        </Styled.Label>
      )}
      <CreatableSelect
        isMulti={multiple}
        options={[...options, ...newOptions]}
        isClearable
        onChange={handleChange}
        onCreateOption={allowCreation ? handleCreateOption : undefined}
        isValidNewOption={allowCreation ? (inputValue) => !!inputValue.trim() : () => false}
        value={multiple ? (value as T[]) : (value as T)?.value ? (value as T) : undefined}
        placeholder={placeholder}
        isDisabled={disabled}
        onFocus={() => onFocus()}
        components={{
          ValueContainer: ({ children, ...props }) => (
            <components.ValueContainer {...props}>
              {startIcon && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "5px",
                    marginRight: "5px",
                    color: "rgba(0, 0, 0, 0.54)"
                  }}
                >
                  {startIcon}
                </div>
              )}
              {children}
            </components.ValueContainer>
          )
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: errorMessage ? "#ffcccc" : "inherit",
            borderColor: state.isFocused ? "#ff8b47" : "inherit",
            boxShadow: state.isFocused ? "0 0 0 1px #ff8b47" : "none",
            minHeight: "50px",
            height: "auto",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center"
          }),
          valueContainer: (provided) => ({
            ...provided,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            minHeight: "50px",
            height: "auto",
            padding: "5px"
          }),
          multiValue: (provided) => ({
            ...provided,
            margin: "2px",
            maxWidth: "100%",
            display: "flex",
            flexWrap: "wrap"
          }),
          multiValueLabel: (provided) => ({
            ...provided,
            whiteSpace: "normal"
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "auto"
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#ffffff",
            zIndex: 9999,
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)"
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ff8b47" : "#ffffff",
            color: state.isFocused ? "#ffffff" : "#333",
            cursor: "pointer"
          })
        }}
      />
      {errorMessage && (
        <ErrorMessage>
          {Array.isArray(errorMessage) ? (
            <div>
              {errorMessage.map((error) => (
                <p key={error as string}>{error as string}</p>
              ))}
            </div>
          ) : (
            <p>{errorMessage}</p>
          )}
        </ErrorMessage>
      )}
    </FormControl>
  );
}
